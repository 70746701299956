import React from "react";
import bodyimg from "../../assets/image/bodyimg.png";
import Fade from "react-reveal/Fade";
import "../Body/css/body.css";
const Body = () => {
  return (
    <>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          backgroundColor: "#141414",
        }}
      >
        <div className="body-grid">
          <div>
            {" "}
            <div>
              <Fade top>
                <h2>Introducing Flux</h2>
              </Fade>
            </div>
            <div>
              <Fade top>
                <p style={{ textTransform: "capitalize", textAlign:"justify" }}>
                get on board flux innovations ltd - the innovative, 
                dynamic and on - demand solution to your needs. 
                we came up with the idea of flux innovations ltd with you in mind, 
                flux innovations ltd is your track to personalised softwares. 
                with our range of products, solutions, and digital concierge services, 
                building different services based on your own terms has never been easier!.
                </p>
              </Fade>
            </div>
          </div>
          <div>
            <div className="body-img">
              <img width="190%" src={bodyimg} alt="flux" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
