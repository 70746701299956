import React from "react";
import Firstwebsites from "../../components/Website/Website";
import bodyimg from "../../assets/image/bodyimg.png";
import { Link } from "react-router-dom";
import "../../components/more/css/more.css";
import Nav from "../About/Nav/Nav";
import Wealth from "../../components/Wealth/Wealth";
const Websites = () => {
  return (
    <>
      <nav>
        <Nav></Nav>
      </nav>
      <div>
        <Firstwebsites></Firstwebsites>
      </div>
      <div>
        <div className="manage-body">
          <div className="center-text">
            <div>
              <div>
                <h3>About Flux Websites</h3>
              </div>
              <h2>
                We treat you like you are our top priority because you are!
              </h2>
            </div>
            <div>
              <p>
                You are in for a treat! Enjoy exclusive products, portfolio
                management and access to expert software advisors when you sign
                up as a Flux Innovations client. This proposition is strictly by
                invitation. Please get in touch with our Concierge to find out
                more.
              </p>
              <div className="download-link">
                <div>
                  <Link to="/contact-us">
                    {" "}
                    <button>Find out more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more-grid">
        <div className="more-grid-holder">
          <div>
            {" "}
            <h2>Your Business... Your Legacy</h2>
          </div>
          <div>
            <p>
              Whatever your business goals are, we will work with you to design
              tailored solutions that fit your unique aspirations.
            </p>
          </div>
          <div>
            <p>
              Our goal is to help create, grow and manage your websites for
              today and in the future.
            </p>
          </div>
        </div>
        <div>
          <div style={{ display: "grid", placeItems: "center" }}>
            <img src={bodyimg} style={{ height: "27rem" }} alt="flux" />
          </div>
        </div>
      </div>{" "}
      <div>
        <Link to="/e-commerce-solutions">
          {" "}
          <Wealth></Wealth>
        </Link>
      </div>
    </>
  );
};

export default Websites;
