import "./App.css";
import Loading from "./components/Loading/Loading";
import Management from "./pages/management/Management";
import NewInvestment from "./pages/investments/NewInvestment";
import Essentials from "./pages/Life/Essentials";
import Concierge from "./pages/Digital/Concierge";
import Scroll from "./components/Scroll/Scroll";
import Infra from "./pages/Cloud/Infra";
import Data from "./pages/Data/Data";
import Cyber from "./pages/Cyber/Cyber";
import Web from "./pages/Web/Web";
import Websites from "./pages/Websites/Websites";
import Home from "./pages/Home";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import Cursor from "./components/cursor/Cursor";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Scroll>
          <Cursor></Cursor>
          <Loading>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/e-commerce-solutions" element={<Management />} />
              <Route path="/software-consulting" element={<NewInvestment />} />
              <Route path="/ussd-applications" element={<Essentials />} />
              <Route path="/system-integration" element={<Concierge />} />
              <Route path="/cloud-infrastructure-integration" element={<Infra />} />
              <Route path="/data-analysis-consulting" element={<Data />} />
              <Route
                path="/cyber-security-penetration-testing"
                element={<Cyber />}
              />
              <Route path="/web-applications" element={<Web />} />
              <Route path="/website" element={<Websites />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/*" element={<Navigate to={"/"} />} />
            </Routes>
          </Loading>
        </Scroll>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
