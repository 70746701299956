import React from "react";
import "../Digital/css/digital.css";
import { ParallaxBanner } from "react-scroll-parallax";
import hero from "../Life/image/04hero.jpg";
const Cyber = () => {
  return (
    <>
      {" "}
      <div style={{ heigh: "100vh" }}>
        <ParallaxBanner
          layers={[
            { image: hero, speed: 25 },
            { image: hero, speed: 25 },
          ]}
          className=" aspect aspect-[2/1]"
        >
          <div className="wealth-hero-holder">
            {" "}
            <div style={{ display: "grid", placeItems: "center" }}>
              {" "}
              <div className="wealth-hero-text">
                <h1 style={{ color: "white" }}>
                  CYBER SECURITY( PENETRATION TESTING ){" "}
                </h1>
              </div>
            </div>
          </div>
        </ParallaxBanner>
      </div>
    </>
  );
};

export default Cyber;
