import React from "react";
import headervid from "../Home/image/headervid.mp4";
import flux from "../Home/image/FLUX.PNG";
import "../Home/css/header.css";
import { Link } from "react-router-dom";
import { SUPPORT_EMAIL, SUPPORT_PHONE } from "../../constants";

const Header = () => {
  return (
    <>
      <div className="header">
        <div className="header-image">
          <img src={flux} alt="" />
          <div
            style={{ width: "100vw", display: "grid", placeItems: "center" }}
          >
            <h3 className="hover">Dream. Create. Inspire.</h3>
          </div>
          <div className="header-contact">
            <Link
              style={{ textDecoration: "none", color: "white", display:"flex" }}
              to="/contact-us?contactMethod=phone"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-telephone-outbound-fill"
                viewBox="0 0 16 16"
                style={{ marginTop: "10px", marginBottom: "10px",alignSelf: "center" }}
              >
                <path
                 fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            <span style={{marginLeft:"10px",alignSelf: "center"}}>{SUPPORT_PHONE}</span>
              
            </Link>
          </div>
          <div className="header-contact">
            <Link
              style={{ textDecoration: "none", color: "white" , display:"flex"}}
              to="/contact-us?contactMethod=email"
            >
           <svg xmlns="http://www.w3.org/2000/svg"  
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 96 960 960"
            style={{ marginTop: "10px", marginBottom: "10px",alignSelf: "center" }}><path d="M145.087 910.131q-32.507 0-55.862-23.356-23.356-23.355-23.356-55.862V321.087q0-32.74 23.356-56.262 23.355-23.521 55.862-23.521h669.826q32.74 0 56.262 23.521 23.521 23.522 23.521 56.262v509.826q0 32.507-23.521 55.862-23.522 23.356-56.262 23.356H145.087ZM480 612.088l-334.913-223v441.825h669.826V389.088L480 612.088Zm0-73.001 330.913-218H150.087l329.913 218ZM145.087 389.088v-68.001 509.826-441.825Z"/></svg>
            <span style={{marginLeft:"10px",alignSelf: "center"}}>{SUPPORT_EMAIL}</span>
            </Link>
          </div>
        </div>
        <div className="video-container">
          <div className="video-overlay"></div>
          <video autoPlay loop muted>
            <source src={headervid} type="video/mp4"></source>
          </video>
        </div>
      </div>
    </>
  );
};

export default Header;
