import React from "react";
import { Link } from "react-router-dom";
import Body from "../components/Body/Body";
import Cloud from "../components/Cloud/Cloud";
import Cyber from "../components/Cyber/Cyber";
import Data from "../components/Data/Data";
import Digital from "../components/Digital/Digital";
import Footer from "../components/footer/Footer";
import Header from "../components/Home/Header";
import Investment from "../components/investments/Investment";
import Life from "../components/Life/Life";
import Nav from "../components/Navigation/Nav";
import Wealth from "../components/Wealth/Wealth";
import Web from "../components/Web/Web";
import Website from "../components/Website/Website";

const Home = () => {
  return (
    <>
      <div>
        <nav>
          <Nav></Nav>
        </nav>
        <div>
          <div>
            {" "}
            <Header></Header>
          </div>
          <div>
            <Body></Body>
          </div>
          <div>
            <Link to="/e-commerce-solutions">
              <Wealth></Wealth>
            </Link>
          </div>
          <div>
            <Link to="/software-consulting">
              {" "}
              <Investment></Investment>
            </Link>
          </div>
          <div>
            <Link to="/ussd-applications">
              <Life></Life>
            </Link>
          </div>
          <div>
            <Link to="/system-integration">
              {" "}
              <Digital></Digital>
            </Link>
          </div>
          <div>
            <Link to="/cloud-infrastructure-integration">
              <Cloud></Cloud>
            </Link>
          </div>
          <div>
            <Link to="/data-analysis-consulting">
              <Data></Data>
            </Link>
          </div>
          <div>
            <Link to="/cyber-security-penetration-testing">
              <Cyber></Cyber>
            </Link>
          </div>
          <div>
            <Link to="/web-applications">
              <Web></Web>
            </Link>
          </div>
          <div>
            <Link to="/website">
              <Website></Website>
            </Link>
          </div>
          <footer>
            <Footer></Footer>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Home;
