import React from "react";
import body from "../../../assets/image/bodyimg.png";
import "../css/about.css";
import Fade from "react-reveal/Fade";

const Body = () => {
  return (
    <>
      <div style={{ background: "#202020", width: "100vw" }}>
        <div className="about-us-grid-holder">
          {" "}
          <div className="about-us-grid">
            <div className="about-us-text-holder">
              <div style={{ overflowY: "hidden" }}>
                <Fade left>
                  {" "}
                  <h1>INTRODUCING FLUX INNOVATIONS LTD.</h1>
                </Fade>
              </div>
              <div>
                <Fade left>
                  <p>
                    GET ON BOARD FLUX INNOVATIONS LTD - THE INNOVATIVE, DYNAMIC
                    AND ON - DEMAND SOLUTION TO YOUR NEEDS. WE CAME UP WITH THE
                    IDEA OF FLUX INNOVATIONS LTD WITH YOU IN MIND, FLUX
                    INNOVATIONS LTD IS YOUR TRACK TO PERSONALISED SOFTWARES.{" "}
                    <br /> <br /> WITH OUR RANGE OF PRODUCTS, SOLUTIONS, AND
                    DIGITAL CONCIERGE SERVICES, BUILDING DIFFERENT SERVICES
                    BASED ON YOUR OWN TERMS HAS NEVER BEEN EASIER!.
                  </p>
                </Fade>
              </div>
            </div>

            <div>
              <div className="about-us-img-holder">
                <img width="80%" src={body} alt="flux" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
