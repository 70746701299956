import React from "react";
import "../Digital/css/digital.css";
import { ParallaxBanner } from "react-scroll-parallax";
import hero from "../Wealth/image/wealth.jpg";

const Cloud = () => {
  return (
    <>
      {" "}
      <div style={{ heigh: "100vh" }}>
        <ParallaxBanner
          layers={[
            { image: hero, speed: 25 },
            { image: hero, speed: 25 },
          ]}
          className=" aspect aspect-[2/1]"
        >
          <div className="wealth-hero-holder">
            {" "}
            <div className="wealth-hero-text">
              {" "}
              <h1>CLOUD INFRASTRUCTURE Intergration</h1>
            </div>
          </div>
        </ParallaxBanner>
      </div>
    </>
  );
};

export default Cloud;
