import React from "react";
import Investment from "../../components/investments/Investment";
import Life from "../../components/Life/Life";
import More from "../../components/more/More";
import { Link } from "react-router-dom";
import Nav from "../../components/Navigation/Nav";

const NewInvestment = () => {
 
  return (
    <>
      <nav>
        <Nav></Nav>
      </nav>
      <div>
        <Investment></Investment>
      </div>
      <div>
        <div className="manage-body">
          <div className="center-text">
            <div>
              <div>
                <h3>About Flux Wealth Management</h3>
              </div>
              <h2>
                We treat you like you are our top priority because you are!
              </h2>
            </div>
            <div>
              <p>
                You are in for a treat! Enjoy exclusive products, portfolio
                management and access to expert investment advisors when you
                sign up as a Wealth Management client. This proposition is
                strictly by invitation. Please get in touch with our Concierge
                to find out more.
              </p>
              <div className="download-link">
                <div>
               
                </div>
                <div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <More></More>
      <div>
        <Link to="/ussd-applications">
          <Life></Life>
        </Link>
      </div>
    </>
  );
};

export default NewInvestment;
