import React from "react";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import "../footer/css/footer.css";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-container">
        <div className="footer-grid">
          <div className="inner-grid">
            {/* <div>
              <p>F.A.Qs</p>
            </div>
            <div>
              <p>Terms and Conditions</p>
            </div> */}
            <div onClick={()=> navigate("/")}>
            <p>HOME</p>
            </div>
            <div onClick={()=> navigate("/contact-us")}>
            <p>CONTACT US</p>
            </div>
          </div>
          <div className="socials">
            <div className="social-grid">
              <div>
                <BsInstagram
                  style={{ color: "#5B5BFA", fontSize: "1.5rem" }}
                ></BsInstagram>
              </div>
              <div>
                <BsTwitter
                  style={{ color: "#5B5BFA", fontSize: "1.5rem" }}
                ></BsTwitter>
              </div>
              <div>
                <BsFacebook
                  style={{ color: "#5B5BFA", fontSize: "1.5rem" }}
                ></BsFacebook>
              </div>
              <div>
                <AiOutlineLinkedin
                  style={{ color: "#5B5BFA", fontSize: "1.5rem" }}
                ></AiOutlineLinkedin>
              </div>
            </div>
          </div>
          <div className="copyb" onClick={()=> navigate("/about")}>
            {" "}
            <h5>
              Copyright ©{" "}
              <span style={{ marginRight: "2px", marginLeft: "2px" }}>
                {new Date().getFullYear()}
              </span>{" "}
              FLUX INNOVATIONS LTD.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
