import React from "react";
import Investment from "../../components/investments/Investment";
import Wealth from "../../components/Wealth/Wealth";
import "../management/css/manage.css";
import bodyimg from "../../assets/image/bodyimg.png";
import { Link } from "react-router-dom";
import Nav from "../About/Nav/Nav";

const Management = () => {
  return (
    <>
      <nav>
        <Nav></Nav>
      </nav>
      <div>
        <Wealth></Wealth>
      </div>
      <div>
        <div className="manage-body">
          <div className="center-text">
            <div>
              <div>
                <h3>About Flux E-commerce Solutions</h3>
              </div>
              <h2>
                We treat you like you are our top priority because you are!
              </h2>
            </div>
            <div>
              <p>
                You are in for a treat! Enjoy exclusive products, portfolio
                management and access to expert software advisors when you sign
                up as a Flux Innovations client. This proposition is strictly by
                invitation. Please get in touch with us to find out more.
              </p>
              <div className="download-link">
                <div>
                  <Link to="/contact-us">
                    {" "}
                    <button>Find out more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="more-grid">
          <div className="more-grid-holder">
            <div>
              {" "}
              <h2>Your Business... Your Legacy</h2>
            </div>
            <div>
              <p>
                Whatever your business goals are, we will work with you to
                design tailored solutions that fit your unique aspirations.
                <br /> <br /> This includes using website builder tools, web
                development software, online website development platforms and
                mobile app builders.
              </p>
            </div>
            <div>
              <p>
                Our goal is to help create products and services that can help
                your e-commerce businesses bloom.
              </p>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", placeItems: "center" }}>
              <img src={bodyimg} style={{ height: "27rem" }} alt="flux" />
            </div>
          </div>
        </div>{" "}
      </div>
      <div>
        <Link to="/software-consulting">
          <Investment></Investment>
        </Link>
      </div>
    </>
  );
};

export default Management;
