import React, { useState } from "react";
import Nav from "../../components/Navigation/Nav";
import "./css/contact.css";
import Footer from "../../components/footer/Footer";
import { Alert, Snackbar } from "@mui/material";
import { API_URL, SUPPORT_EMAIL } from "../../constants";
const Contact = () => {
  const [state, setState] = useState({
    fullName: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  function handleChange(e) {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    let [firstName = '', lastName = '', middleName = ''] = (state.fullName ?? "").split(" ");
    let search = new URLSearchParams(window.location.search);
    document.querySelector("#contact-us-submit").className = "contact-us-submit submitting";
    await new Promise((r)=>setTimeout(r,1000));
    try {
      let result = await fetch(`${API_URL}/contacts-us`, {
        method: "POST",
        headers: new Headers({
          "content-type": "application/json",
        }),
        body: JSON.stringify({
          data: {
            ...state,
            firstName,
            lastName,
            middleName,
            preferredContactMethod: search.get("contactMethod")??"email",
            replyTo: SUPPORT_EMAIL
          }
        })
      })
      if (result.status !== 200) {
        if (result.headers.get('content-type')?.includes('json')) {
          let error = await result.json();
          setState((p) => ({
            ...p,
            open: true,
            alertType: "error",
            alertMessage: error?.error.details.errors.map((e) => e.message).join("\n"),
          }));
        } else {
          setState((p) => ({
            ...p,
            open: true,
            alertType: "error",
            alertMessage: "Error submitting request at this time please try again."
          }));
        }
      } else {
        setState({
          fullName: "",
          email: "",
          subject: "",
          phone: "",
          message: "",
          open: true,
          alertMessage: "Your request has been logged successfully"
        });
      }
    } catch (error) {

    }
    document.querySelector("#contact-us-submit").className = "contact-us-submit";
  };
  const handleCloseSnack = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }))
  }
  return (
    <>
      <div className="contact-bg">
        <Snackbar open={state.open} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity={state.alertType ?? "success"} sx={{ width: '100%' }}>
            {state.alertMessage}
          </Alert>
        </Snackbar>
        <nav>
          <Nav></Nav>
        </nav>
        <div>
          <div className="contact-heading">
            <h1 style={{ color: "white" }}>Contact Us</h1>
            <div>
              <p style={{ color: "#5B5BFA" }}>We're here for you, 24/7</p>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="contact-grid-center">
              <div className="contact-grid">
                <div>
                  <input
                    placeholder="What's Your Name"
                    type="text"
                    value={state.fullName}
                    onChange={handleChange}
                    name="fullName"
                  />
                </div>
                <div>
                  <input
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    placeholder="Your phone number"
                    type="text"
                    name="phone"
                    value={state.phone}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    placeholder="How may we be of important to you?"
                    type="text"
                    name="subject"
                    value={state.subject}
                    onChange={handleChange}
                  />
                </div>
              </div>


              <div className="tell-us-about">
                <textarea
                  placeholder="Tell us about it"
                  type="text"
                  name="message"
                  value={state.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div id="contact-us-submit" className="contact-us-submit">
                {" "}
                <input type="submit" />
              </div>
            </div>
          </form>

          <footer>
            <Footer></Footer>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Contact;
