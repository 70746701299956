import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import flux from "../../../components/Navigation/image/FLUX.PNG";
import { Link } from "react-router-dom";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop(!drop);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [show, setShow] = useState(false);
  const [img, setImg] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 500) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  const controlImg = () => {
    if (window.scrollY > 500) {
      setImg(true);
    } else {
      setImg(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlImg);
    return () => {
      window.removeEventListener("scroll", controlImg);
    };
  }, []);
  return (
    <>
      {" "}
      <nav className={img ? "nav nav-active" : "nav"}>
        <div className={img ? "nav-img active" : "nav-img"}>
          <Link to="/home">
            <img width="120px" src={flux} alt="flux logo" />
          </Link>
        </div>

        <div>
          <GiHamburgerMenu
            style={{
              cursor: "pointer",
              fontSize: "1.8rem",
              color: "white",
            }}
            onClick={() => handleToggle()}
          ></GiHamburgerMenu>
        </div>
      </nav>
      <div className="mobile-nav">
        {open ? (
          <div className="menu-dropdown">
            <div>
              <ul className="nav-flex-2">
                <Link style={{ textDecoration: "none" }} to="/home">
                  {" "}
                  <li>Home</li>
                </Link>{" "}
                <li onClick={() => handleDrop()}>Our Services</li>
                {drop ? (
                  <div className="slide-animation">
                    <div className="navigation-dropdown">
                      <a href="/e-commerce-solutions">
                        <p>E-commerce Solutions</p>
                      </a>
                      <a href="/software-consulting">
                        <p>Software Consulting</p>
                      </a>
                      <a href="/ussd-applications">
                        <p>USSD Applications</p>
                      </a>
                      <a href="/system-integration">
                        <p>System Integration</p>
                      </a>
                      <a href="/cloud-infrastructure-integration">
                        <p>Cloud Infrastructure Integration</p>
                      </a>
                      <a href="/data-analysis-consulting">
                        {" "}
                        <p>Data Analysis and Consulting</p>
                      </a>
                      <a href="/cyber-security-penetration-testing">
                        {" "}
                        <p>Cyber Security(Penetration testing)</p>
                      </a>
                      <a href="/web-applications">
                        {" "}
                        <p>Web Application</p>
                      </a>
                      <a href="/website">
                        {" "}
                        <p>Website</p>
                      </a>
                    </div>
                  </div>
                ) : null}
                <Link style={{ textDecoration: "none" }} to="/about">
                  {" "}
                  <li>About us</li>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/contact-us">
                  {" "}
                  <li>Contact us</li>
                </Link>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Nav;
