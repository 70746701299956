import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import hero from "../../components/Digital/image/02hero.jpg";
import Footer from "../../components/footer/Footer";
import "../About/css/about.css";
import Body from "./aboutbody/Body";
import Ourservices from "./aboutbody/Ourservices";

import Nav from "./Nav/Nav";
const About = () => {
  return (
    <>
      <div>
        <nav>
          <Nav></Nav>
        </nav>{" "}
        <div style={{ heigh: "100vh" }}>
          <ParallaxBanner
            layers={[
              { image: hero, speed: 25 },
              { image: hero, speed: 25 },
            ]}
            className=" aspect aspect-[2/1]"
          >
            <div className="wealth-hero-holder">
              {" "}
              <div className="wealth-hero-text">
                {" "}
                <h1 style={{ color: "white" }}>About US</h1>
              </div>
            </div>
          </ParallaxBanner>
        </div>
        <div>
          <Body></Body>
        </div>
        <section>
          <Ourservices></Ourservices>
        </section>
        <footer>
          <Footer></Footer>
        </footer>
      </div>
    </>
  );
};

export default About;
