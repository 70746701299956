import React, { useEffect, useState } from "react";
let timer ;
const Cursor = () => {
  const [cursorX, setCursorX] = useState();
  const [cursorY, setCursorY] = useState();
  const [className, setClassName] = useState(["cursor"]);
  window.addEventListener("mousemove", (e) => {
    setCursorX(e.pageX);
    setCursorY(e.pageY);
    addClassName("cursor-block");
    if(!className.includes("cursor-enter")){
      addClassName("cursor-enter");
    }
    if(timer){
      clearTimeout(timer);
    }
    timer = setTimeout(()=>{
      removeClassName("cursor-enter");
    },1000*5);
  });
  // mouse effect
  const mouseControl = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      addClassName("cursor-block");
    } else {
      removeClassName("cursor-block");
    }
  };


  const addClassName = (name) => {
    setClassName((className) => {
      className = className.filter((e) => e !== name);
      className.push(name);
      return className;
    });
  }
  const removeClassName = (name) => {
    setClassName((className) => {
      className = className.filter((e) => e !== name);
      return className;
    });
  }
  window.addEventListener("mouseout", (e) => {
    removeClassName("cursor-enter");
  });
  // mouse useeffect
  useEffect(() => {
    window.addEventListener("scroll", mouseControl);
    return () => {
      window.removeEventListener("scroll", mouseControl);
    };
  }, []);
  return (
    <div>
      {" "}
      <div
        className={className.join(" ")}
        style={{ left: cursorX + "px", top: cursorY + "px" }}
      ></div>
    </div>
  );
};

export default Cursor;
