import React from "react";
import Fade from "react-reveal/Fade";
import "../css/about.css";

const Ourservices = () => {
  const services = [
    {
      id: 1,
      name: "E COMMERCE SOLUTIONS",
      link: "/e-commerce-solutions",
    },
    {
      id: 2,
      name: "SOFTWARE CONSULTING",
      link: "/software-consulting",
    },
    {
      id: 3,
      name: "USSD APPLICATIONS",
      link: "/ussd-applications",
    },
    {
      id: 4,
      name: "SYSTEM INTEGRATION",
      link: "/system-integration",
    },
    {
      id: 5,
      name: "CLOUD INFRASTRUCTURE INTEGRATION",
      link: "/cloud-infrastructure-integration",
    },
    {
      id: 6,
      name: "DATA ANALYSIS AND CONSULTING",
      link: "/data-analysis-consulting",
    },
    {
      id: 7,
      name: "CYBER SECURITY ( PENETRATION TESTING)",
      link: "/cyber-security-penetration-testing",
    },
    {
      id: 8,
      name: "WEB APPLICATION",
      link: "/web-applications",
    },
    {
      id: 9,
      name: "WEBSITE",
      link: "/website",
    },
  ];
  return (
    <>
      <div className="about-our-services">
        <div>
          <h1 style={{ textTransform: "Uppercase" }}>Our Services</h1>
        </div>
        <div className="text-center-our-services">
          <Fade top>
            <p>
              <strong style={{ marginRight: "5px" }}>
                WE TREAT YOU LIKE YOU ARE OUR TOP PRIORITY WHICH YOU ARE!
              </strong>
              YOU ARE IN FOR A TREAT!. ENJOY EXCLUSIVE PRODUCTS AND GET ACCESS
              TO OUR AMAZING SERVICES WHICH YOU GET TO CHOOASE FROM ANY OF OUR
              SERVICES WHEN YOU REGISTER WITH US, PLEASE GET IN TOUCH WITH OUR
              CONCIERGE TO FIND OUT MORE EXCLUSIVE DEALS!. <br /> <br />{" "}
              WHATEVER YOUR GOALS ARE, WE WILL WORK WITH YOU TO ACHIEVE THEM ALL
              WITH OUR EXCLUSIVE AND AWESOME SERVICES THAT FITS YOUR UNIQUE
              DESCRIPTION OF YOURSELF AND BUSINESS. OUR GOAL IS TO HELP YOUR
              ACTUALIZE YOUR DREAMS BY HELPING WITH OUR SERVICES LIKE E.G
              CREATING A WEBSITE , APPS, CONSULTING US REGARDING A PARTICULAR
              IDEA AND ALSO PROFFER SOLUTIONS TO ANY SETBACKS OR PROBLEMS.{" "}
              <strong>Here are a list of the services we offer</strong>
            </p>
          </Fade>
        </div>
        <div className="our-services-lists">
          <ul>
            {services.map((service) => (
              <div key={service.id}>
                <Fade top>
                  <a href={service.link}>
                    {" "}
                    <li>{service.name}</li>
                  </a>
                </Fade>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Ourservices;
